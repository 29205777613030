<template>
  <b-form class="rule-form">
    <validation-observer
      ref="createForm"
      v-slot="{ handleSubmit }"
      class="mr-4 ml-4"
    >
      <b-form @submit.stop.prevent="handleSubmit()">
        <template v-for="(value, index) in VOCAB_GAME_CONFIG">
          <div :key="index" class="pb-2">
            <h4 class="mb-1">{{ VOCAB_GAME[index] }}</h4>
            <validation-provider
              :name="`countdown-${index}`"
              v-slot="validationContext"
              :rules="{ required: true, regex: '^([0-9]+)$' }"
            >
              <b-row class="pb-2">
                <b-col sm="4">
                  <label>Time (seconds)</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input
                    v-model.number="model[value]"
                    :name="`countdown-${index}`"
                    type="text"
                    :state="getValidationState(validationContext)"
                    placeholder="s"
                  />
                </b-col>
              </b-row>
              <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>
        </template>
      </b-form>
      <div class="text-center my-2">
        <b-button type="button" variant="primary" @click="onSave">Save</b-button>
      </div>
    </validation-observer>
  </b-form>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import {VOCAB_GAME, VOCAB_GAME_CONFIG} from '../../constants'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: {
    vocabCountdownTime: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isLoading: false,
      model: {},
      VOCAB_GAME,
      VOCAB_GAME_CONFIG
    }
  },
  watch: {
    vocabCountdownTime() {
      this.getModel()
    }
  },

  methods: {
    getModel() {
      if (this.vocabCountdownTime) {
        this.model = {...this.vocabCountdownTime}
      }
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.$emit('save', this.model)
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  }
}
</script>
<style lang="scss" scoped>
.rule-form {
  margin: 0 200px !important;
  label {
    font-size: 16px;
  }
}
</style>
