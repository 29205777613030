<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Learning Config" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Star Rating Rule</h2>
          </b-col>
          <b-col cols="2">
            <b-select
              v-model="stageType"
              class="select-style"
              name="stage-type"
              :options="typeOptions"
            />
          </b-col>
        </b-row>
        <b-card>
          <star-rating-config-form
            :star-rating-rule="starRatingRule.value"
            :stage-type="stageType"
            @save="onSaveStarRating"
          />
        </b-card>
      </section>
      <section class="py-3">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Vocab Countdown Time</h2>
          </b-col>
        </b-row>
        <b-card>
          <vocab-countdown-config-form
            :vocab-countdown-time="vocabCountdownTime.value"
            @save="onSaveVocabCountdown"
          />
        </b-card>
      </section>
      <section class="py-3">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Max Hearts Per Lesson</h2>
          </b-col>
          <b-col cols="2">
            <b-select
              v-model="stageTypeHeartConfig"
              class="select-style"
              name="stage-type-heart-config"
              :options="typeStageHeartConfigOptions"
            />
          </b-col>
        </b-row>
        <b-card>
          <max-heart-per-stage-config-form
            :max-heart-per-stage="maxHeartPerStageConfig.value"
            :stage-type="stageTypeHeartConfig"
            :is-loading="isLoading"
            @save="onSaveMaxHeartPerStage"
          />
        </b-card>
      </section>
    </b-media>
  </div>
</template>

<script>
import service from "../service";
import StarRatingConfigForm from './_components/StarRatingConfigForm.vue'
import VocabCountdownConfigForm from './_components/VocabCountdownConfigForm.vue'
import MaxHeartPerStageConfigForm from './_components/MaxHeartPerStageConfigForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import { STAR_RATING_RULES_DEFAULT, VOCAB_COUNTDOWN_TIME_DEFAULT, MAX_HEAR_PER_STAGE_DEFAULT } from './../defaultObject'

const MAX_HEAR_PER_STAGE_KEY = 'MAX_HEAR_PER_STAGE_KEY'
const STAR_RATING_RULES_KEY = 'STAR_RATING_RULES'
const VOCAB_COUNTDOWN_TIME_KEY = 'VOCAB_COUNTDOWN_TIME'

export default {
  components: {
    StarRatingConfigForm,
    VocabCountdownConfigForm,
    MaxHeartPerStageConfigForm,
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      isLoading: false,
      stageType: 1,
      stageTypeHeartConfig: 2,
      maxHeartPerStageConfig: {},
      starRatingRule: {},
      vocabCountdownTime: [],
      typeOptions:[
        {text: "SPEAKING", value: 1},
        {text: "LISTENING", value: 2},
        {text: "VOCAB", value: 3},
      ],
      typeStageHeartConfigOptions:[
        {text: "LISTENING", value: 2},
        {text: "VOCAB", value: 3},
      ]
    }
  },

  created() {
    this.getMaxHeartPerStageConfig()
    this.getStarRatingRule()
    this.getVocabCountdownTime()
  },

  methods: {
    async getDetail(key, defaultData = {}, category = 'stage') {
      let response = await service.getDetail({
        key
      })
      if (response.data.type === "DATA") {
        if (response.data.data.document) {
          return response.data.data.document
        }
        response = await service.create({ key, value: defaultData, category, type: typeof defaultData })
        if (response.data.type === "DATA") { return response.data.data.new_document }
      }
    },

    async getMaxHeartPerStageConfig() {
      this.maxHeartPerStageConfig = await this.getDetail(MAX_HEAR_PER_STAGE_KEY, MAX_HEAR_PER_STAGE_DEFAULT)
    },

    async getStarRatingRule() {
      this.starRatingRule = await this.getDetail(STAR_RATING_RULES_KEY, STAR_RATING_RULES_DEFAULT)
    },

    async getVocabCountdownTime() {
      this.vocabCountdownTime = await this.getDetail(VOCAB_COUNTDOWN_TIME_KEY, VOCAB_COUNTDOWN_TIME_DEFAULT)
    },

    async onSaveMaxHeartPerStage(value) {
      this.isLoading = true;
      switch(this.stageTypeHeartConfig) {
        case 2:
          this.maxHeartPerStageConfig.value.LISTENING = value.LISTENING
          break
        case 3:
          this.maxHeartPerStageConfig.value.VOCAB = value.VOCAB
          break
      }

      const res = await service.update({ ...this.maxHeartPerStageConfig })
      this.isLoading = false;
      if (res){
        this.getMaxHeartPerStageConfig()
      }
    },

    async onSaveStarRating(value) {
      this.isLoading = true;
      switch(this.stageType) {
        case 1:
          this.starRatingRule.value.CHALLENGE = value
          break
        case 2:
          this.starRatingRule.value.LISTENING = value
          break
        case 3:
          this.starRatingRule.value.VOCAB = value
          break
        case 4:
          this.starRatingRule.value.WORD_CONNECT = value
          break
        case 5:
          this.starRatingRule.value.PICTURE_WORD = value
          break
        case 6:
          this.starRatingRule.value.WORD_PRONUN = value
          break
      }

      const res = await service.update({ ...this.starRatingRule })
      this.isLoading = false;
      if (res){
        this.getStarRatingRule()
      }
    },

    async onSaveVocabCountdown(value) {
      this.isLoading = true;
      this.vocabCountdownTime.value = value
      const res = await service.update({ ...this.vocabCountdownTime })

      this.isLoading = false;
      if (res){
        this.getVocabCountdownTime()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.select-style {
  border: 1px solid #978383 !important;
}
</style>
