
export const VOCAB_GAME = [
    'Matching: 1 word vs n images',
    'Matching: n word vs n i mages',
    'Matching: n images vs 1 word',
]

export const VOCAB_GAME_CONFIG = [
  'GAME_1',
  'GAME_2',
  'GAME_3',
]