<template>
  <b-form class="rule-form">
    <validation-observer
      ref="createForm"
      v-slot="{ handleSubmit }"
      class="mr-4 ml-4"
    >
      <div class="text-center pb-2"><h3>{{ getStageName }}</h3></div>
      <b-form v-if="stageType === 1" @submit.stop.prevent="handleSubmit()">
        <validation-provider
          name="One Star"
          v-slot="validationContext"
          :rules="{ required: true, regex: '^([0-9]+)$' }"
        >
          <b-row class="pb-2">
            <b-col sm="4">
              <label>One Star (%)</label>
            </b-col>
            <b-col sm="6">
              <b-form-input
                v-model.number="model.ONE_STAR"
                name="one-star"
                type="number"
                :state="getValidationState(validationContext)"
                placeholder="%"
                aria-describedby="input-category-name-feedback"
              />
            </b-col>
          </b-row>
          <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider
          name="Two Star"
          v-slot="validationContext"
          :rules="{ required: true, regex: '^([0-9]+)$' }"
        >
          <b-row class="pb-2">
            <b-col sm="4">
              <label>Two Star (%)</label>
            </b-col>
            <b-col sm="6">
              <b-form-input
                v-model.number="model.TWO_STAR"
                name="two-star"
                type="number"
                :state="getValidationState(validationContext)"
                placeholder="%"
              />
            </b-col>
          </b-row>
          <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider
          name="Three Star"
          v-slot="validationContext"
          :rules="{ required: true, regex: '^([0-9]+)$' }"
        >
          <b-row class="pb-2">
            <b-col sm="4">
              <label>Three Star (%)</label>
            </b-col>
            <b-col sm="6">
              <b-form-input
                v-model.number="model.THREE_STAR"
                name="three-star"
                type="number"
                :state="getValidationState(validationContext)"
                placeholder="%"
              />
            </b-col>
          </b-row>
          <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form>

      <b-form v-else-if="stageType === 2 || stageType === 4 || stageType === 5 || stageType === 6" @submit.stop.prevent="handleSubmit()">
        <validation-provider
          name="One Star"
          v-slot="validationContext"
          :rules="{ required: true, regex: '^([0-9]+)$' }"
        >
          <b-row class="pb-2">
            <b-col sm="4">
              <label>One Star</label>
            </b-col>
            <b-col sm="6">
              <b-form-input
                v-model.number="model.ONE_STAR"
                name="one-star"
                type="number"
                :state="getValidationState(validationContext)"
                placeholder="%"
                aria-describedby="input-category-name-feedback"
              />
            </b-col>
          </b-row>
          <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
        <validation-provider
          name="Two Star"
          v-slot="validationContext"
          :rules="{ required: true, regex: '^([0-9]+)$' }"
        >
          <b-row class="pb-2">
            <b-col sm="4">
              <label>Two Star</label>
            </b-col>
            <b-col sm="6">
              <b-form-input
                v-model.number="model.TWO_STAR"
                name="two-star"
                type="number"
                :state="getValidationState(validationContext)"
                placeholder="%"
              />
            </b-col>
          </b-row>
          <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form>

      <b-form v-else @submit.stop.prevent="handleSubmit()">
        <template v-for="(value, index) in model">
          <div :key="index" class="pb-2">
            <h4 class="game-title">{{ VOCAB_GAME[index] }}</h4>
            <validation-provider
              :name="`vocab-${index}-one-star`"
              v-slot="validationContext"
              :rules="{ required: true, regex: '^([0-9]+)$' }"
            >
              <b-row class="pb-2">
                <b-col sm="4">
                  <label>One Star (%)</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input
                    v-model.number="model[index].ONE_STAR"
                    :name="`vocab-${index}-one-star`"
                    type="text"
                    :state="getValidationState(validationContext)"
                    placeholder="%"
                  />
                </b-col>
              </b-row>
              <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              :name="`vocab-${index}-two-star`"
              v-slot="validationContext"
              :rules="{ required: true, regex: '^([0-9]+)$' }"
            >
              <b-row class="pb-2">
                <b-col sm="4">
                  <label>Two Star (%)</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input
                    v-model.number="model[index].TWO_STAR"
                    :name="`vocab-${index}-two-star`"
                    type="text"
                    :state="getValidationState(validationContext)"
                    placeholder="%"
                  />
                </b-col>
              </b-row>
              <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
            </div>
          </template>
      </b-form>
      <div class="text-center my-2">
        <b-button type="button" variant="primary" @click="onSave">Save</b-button>
      </div>
    </validation-observer>
  </b-form>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import { VOCAB_GAME } from '../../constants'
const STAGE_NAME_CONFIG = [
  'CHALLENGE',
  'LISTENING',
  'VOCAB',
]
const STAGE_NAME = [
  'SPEAKING',
  'LISTENING',
  'VOCAB',
]
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: {
    starRatingRule: {
      type: Object,
      default: () => {}
    },

    stageType: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      isLoading: false,
      model: {},
      STAGE_NAME,
      STAGE_NAME_CONFIG,
      VOCAB_GAME
    }
  },

  computed: {
    getStageName() {
      return STAGE_NAME[this.stageType - 1]
    },
    getStageNameConfig() {
      return STAGE_NAME_CONFIG[this.stageType - 1]
    }
  },

  watch: {
    starRatingRule() {
      this.getModel()
    },

    stageType() {
      this.getModel()
    }
  },

  methods: {
    getModel() {
      if (this.starRatingRule[this.getStageNameConfig]) {
        this.model = {...this.starRatingRule[this.getStageNameConfig]}
      }
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.$emit('save', this.model)
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  }
}
</script>
<style lang="scss" scoped>
.rule-form {
  margin: 0 200px !important;
  label {
    font-size: 16px;
  }
  .game-title {
    margin-left: -150px;
  }
}
</style>
